<template>
  <el-scrollbar class="elv-duplicates-table" wrap-class="elv-duplicates-table-wrap">
    <div class="elv-duplicates-table-container">
      <div class="elv-duplicates-table-header">
        <div class="elv-duplicates-table-header__item"></div>
        <div class="elv-duplicates-table-header__item">{{ t('report.Datetime') }}</div>
        <div class="elv-duplicates-table-header__item">{{ t('report.Source') }}</div>
        <div class="elv-duplicates-table-header__item">{{ t('report.Currency') }}</div>
        <div class="elv-duplicates-table-header__item">{{ t('report.Amount (In)') }}</div>
        <div class="elv-duplicates-table-header__item">{{ t('report.Amount (Out)') }}</div>
        <div class="elv-duplicates-table-header__item">{{ t('report.From/To') }}</div>
        <div class="elv-duplicates-table-header__item">{{ t('report.category') }}</div>
        <div class="elv-duplicates-table-header__item"></div>
      </div>

      <el-scrollbar view-class="elv-duplicates-table-content">
        <div v-for="(item, index) in props.tableData" :key="index" class="elv-duplicates-table-content-transaction">
          <div class="elv-duplicates-table-content-transaction__total">
            <p>{{ t('report.transactionsTotal', { total: item?.list?.length }) }}</p>
            <div class="elv-duplicates-table-content-transaction__keepAll" @click="onKeepAll(item.list)">
              <SvgIcon name="duplicates-batch-Ignore" width="18" height="18" />
              {{ t('report.keepAll') }}
            </div>
          </div>

          <div
            v-for="(trx, i) in item?.list"
            :key="i"
            class="elv-duplicates-table-content-row"
            @click="onCellClicked(trx, $event)"
          >
            <div class="elv-duplicates-table-content-row__index elv-duplicates-table-content-cell">{{ i + 1 }}</div>
            <div class="elv-duplicates-table-content-row__dateTime elv-duplicates-table-content-cell">
              <DateTimeCell :params="{ data: trx }" />
            </div>
            <div class="elv-duplicates-table-content-row__source elv-duplicates-table-content-cell">
              <CurrencyCell :params="{ data: trx, value: { cellName: 'Source' } }" />
            </div>
            <div class="elv-duplicates-table-content-row__currency elv-duplicates-table-content-cell">
              <CurrencyCell :params="{ data: trx, value: { cellName: 'Currency' } }" />
            </div>
            <div class="elv-duplicates-table-content-row__amountIn elv-duplicates-table-content-cell">
              <BorrowCell :params="{ data: trx, value: { cellName: 'Amount (In)' } }" />
            </div>
            <div class="elv-duplicates-table-content-row__amountOut elv-duplicates-table-content-cell">
              <BorrowCell :params="{ data: trx, value: { cellName: 'Amount (Out)' } }" />
            </div>
            <div class="elv-duplicates-table-content-row__fromTo elv-duplicates-table-content-cell">
              <FromToCell :params="{ data: trx }" />
            </div>
            <div class="elv-duplicates-table-content-row__event elv-duplicates-table-content-cell">
              <EventCell :params="{ data: trx }" />
            </div>
            <div class="elv-duplicates-table-content-row__operating elv-duplicates-table-content-cell">
              <el-tooltip
                :show-arrow="false"
                effect="light"
                placement="top"
                popper-class="elv-table-tips"
                overlay-classname="elv-table-tips"
                :show-after="500"
              >
                <SvgIcon name="duplicates-Ignore" width="18" height="18" class="elv-duplicates-table-ignore" />
                <template #content>
                  <div class="elv-table-tips-content">{{ t('report.discard') }}</div>
                </template>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-scrollbar>

  <AccountOrContactDrawer
    ref="overlayDrawerRef"
    :drawer-data="overlayDrawerData.currentData"
    :is-batch="overlayDrawerData.isBatch"
    :drawer-title="overlayDrawerData.drawerTitle"
    :table-type="overlayDrawerData.type"
    :total-contacts="0"
    @onUpdateAccountOrContact="onUpdateAccountOrContact"
  />

  <TransactionEditOverlay
    ref="transactionEditOverlayRef"
    :is-batch="false"
    :current-data="overlayDrawerData.currentData"
  />

  <MessageBox
    ref="duplicateMessageBoxRef"
    :confirm-button-text="t('report.keepAll')"
    :cancel-button-text="t('button.cancel')"
    :title="t('report.keepAll')"
    :loading="keepAllLoading"
    @onConfirmEvent="onConfirmKeepAll"
    @onCancelEvent="onCancelKeepAll"
  >
    <template #content>
      <span class="elv-duplicates-confirm-header__title">{{ t('report.keepAllConfirm') }}</span></template
    >
  </MessageBox>
</template>

<script setup lang="ts">
import { map } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import MessageBox from '@/components/MessageBox.vue'
import { TransactionItemType } from '#/ReportsTypes'
import TransactionEditOverlay from './EditOverlay.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import EventCell from '@/components/Reports/Cell/EventCell.vue'
import BorrowCell from '@/components/Reports/Cell/BorrowCell.vue'
import FromToCell from '@/components/Reports/Cell/FromToCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import AccountOrContactDrawer from '../../components/AccountOrContactDrawer.vue'

const props: any = defineProps({
  tableData: {
    type: Array,
    default: () => {
      return []
    }
  },
  tableHeight: {
    type: String,
    default: `calc(100vh - 219px)`
  }
})

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()

const overlayDrawerData = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false
})
const keepAllLoading = ref(false)
const overlayDrawerRef = ref()
const duplicateMessageBoxRef = ref()
const transactionEditOverlayRef = ref()
const transactionList = ref<TransactionItemType[]>([])
const emit = defineEmits(['resetDuplicateDataList'])

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const onCellClicked = async (item: any, e: any) => {
  try {
    overlayDrawerData.currentData = {}
    if (
      e.target?.className === 'elv-financials-cell-fromTo-main__address' ||
      e.target?.className === 'elv-financials-cell-fromTo-item-main__logo' ||
      e.target?.className?.baseVal === 'elv-financials-cell-fromTo-item-main__logo' ||
      e.target?.className === 'elv-financials-cell-fromTo-item-main'
    ) {
      const direction = e.target?.attributes['data-type'].value
      switch (direction) {
        case 'from':
          if (item?.fromEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              cardId: item?.fromAddress,
              source: { ...item?.source },
              platform: { ...item?.fromEntityAccount?.platform },
              ...item?.fromEntityAccount
            }
          } else if (item?.fromAddressContact?.role) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              cardId: item?.fromAddress,
              source: { ...item?.source },
              ...item?.fromAddressContact
            }
          } else {
            switch (item?.direction) {
              case 'OUT':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  cardId: item?.fromAddress,
                  source: { ...item?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  cardId: item?.fromAddress,
                  source: { ...item?.source }
                }
                break
            }
          }
          break
        default:
          if (item?.toEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              cardId: item?.toAddress,
              source: { ...item?.source },
              platform: { ...item?.toEntityAccount?.platform },
              ...item?.toEntityAccount
            }
          } else if (item?.toAddressContact?.role) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              cardId: item?.toAddress,
              source: { ...item?.source },
              ...item?.toAddressContact
            }
          } else {
            switch (item?.direction) {
              case 'IN':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  cardId: item?.toAddress,
                  source: { ...item?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  cardId: item?.toAddress,
                  source: { ...item?.source }
                }
                break
            }
          }
          break
      }
      overlayDrawerData.isBatch = false
      overlayDrawerData.type = 'transaction'
      overlayDrawerRef.value?.onCheckDrawerStatus()
    } else if (
      e.target?.href?.animVal === '#icon-duplicates-Ignore' ||
      e.target?.classList?.[0] === 'elv-duplicates-table-ignore'
    ) {
      if (!currentEntityPermission.value?.transaction?.viewDetail) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.currentData = item
      const params = {
        activityIds: [item?.activityId],
        categoryNo: '50000'
      }
      await ReportsApi.quickEditEvent(entityId.value, params)
      ElMessage.success(t('message.transactionIgnored'))
      emit('resetDuplicateDataList')
      reportStore.fetchIssueDataList(entityId.value)
      const { data } = await ReportsApi.getActivityEvent(entityId.value, { status: 'REVIEWED' })
      const transactionFilter = {
        ...reportStore.transactionFilter,
        categoryNos: data
      }
      reportStore.processTableListDataInit(entityId.value, 'reviewed', {
        limit: 10,
        page: 1,
        ...transactionFilter
      })
    } else if (
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash' &&
      e.target?.className?.animVal !== 'elv-duplicates-table-ignore' &&
      e.target?.href?.animVal !== '#icon-duplicates-Ignore'
    ) {
      if (!currentEntityPermission.value?.transaction?.viewDetail) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.currentData = item
      transactionEditOverlayRef.value?.onCheckDrawerStatus()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const onUpdateAccountOrContact = (data: any) => {
  overlayDrawerData.currentData = data
}

const onCancelKeepAll = () => {
  transactionList.value = []
  duplicateMessageBoxRef.value?.onCheckMessageBoxDialog()
}
const onKeepAll = (list: TransactionItemType[]) => {
  transactionList.value = list
  duplicateMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmKeepAll = async () => {
  try {
    keepAllLoading.value = true
    const activityIds = map(transactionList.value, 'activityId')
    const params = {
      activityIds
    }
    await ReportsApi.ignoreDuplicateKeepAll(entityId.value, params)
    keepAllLoading.value = false
    ElMessage.success(t('message.transactionKeepAll'))
    reportStore.fetchIssueDataList(entityId.value)
    reportStore.processTableListDataInit(entityId.value, 'reviewed', { limit: 10, page: 1 })
    emit('resetDuplicateDataList')
    onCancelKeepAll()
  } catch (error: any) {
    keepAllLoading.value = false
    console.log(error)
    ElMessage.error(error.message)
  }
}
</script>

<style lang="scss" scoped>
:deep(.elv-table-tips-content) {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

.elv-duplicates-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1e2024;
  word-break: break-word;
}

.elv-duplicates-table {
  width: 100%;

  :deep(.elv-duplicates-table-wrap) {
    overflow-y: hidden;
    margin-bottom: 10px;
  }
}

.elv-duplicates-table-container {
  min-width: fit-content;
}

.elv-duplicates-table-header {
  height: 36px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border-top: 1px solid #e4e7eb;
  border-bottom: 1px solid #ced7e0;
  background: #eef4fb;

  .elv-duplicates-table-header__item {
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #e4e7eb;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #666d74;

    &:nth-child(1) {
      width: 42px;
    }

    &:nth-child(2) {
      width: 150px;
    }

    &:nth-child(3) {
      width: 140px;
    }

    &:nth-child(4) {
      width: 110px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      width: 120px;
      justify-content: flex-end;
    }

    &:nth-child(7) {
      width: 378px;
    }

    &:nth-child(8) {
      width: 160px;
    }

    &:last-child {
      border-right: none;
      width: 68px;
    }
  }
}

:deep(.elv-duplicates-table-content) {
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
  height: v-bind('props.tableHeight');

  .elv-duplicates-table-content-transaction {
    margin-bottom: 8px;
    box-sizing: border-box;
    padding: 0px;
    width: 100%;
    max-width: 1274px;
    border: 1px solid #d2dbe4;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .elv-duplicates-table-content-transaction__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    padding-left: 10px;
    padding-right: 14px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #838d95;
    box-sizing: border-box;
    border-bottom: 1px solid #edf0f3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #636b75;

      svg {
        fill: #838d95;
        margin-right: 10px;
      }

      &:hover {
        color: #0e0f11;

        svg {
          fill: #0e0f11;
        }
      }
    }
  }

  .elv-duplicates-table-content-row {
    min-height: 42px;
    box-sizing: border-box;
    border-bottom: 1px solid #edf0f3;
    display: flex;

    &:hover {
      background: #f9fafb;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .elv-duplicates-table-content-row__index {
        border-bottom-left-radius: 4px;
      }

      .elv-duplicates-table-content-row__operating {
        border-bottom-right-radius: 4px;
      }
    }
  }

  .elv-duplicates-table-content-cell {
    min-height: 42px;
    border-right: 1px solid #edf0f3;
    display: flex;
    align-items: center;
    padding: 0 9px;
    box-sizing: border-box;
    cursor: pointer;

    &:last-child {
      border-right: none;
    }
  }

  .elv-duplicates-table-content-row__index {
    width: 34px;
    padding: 0px;
    justify-content: center;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #aaafb6;
    cursor: unset;
  }

  .elv-duplicates-table-content-row__dateTime {
    width: 150px;
  }

  .elv-duplicates-table-content-row__source {
    width: 140px;
  }

  .elv-duplicates-table-content-row__currency {
    width: 110px;
  }

  .elv-duplicates-table-content-row__amountIn,
  .elv-duplicates-table-content-row__amountOut {
    width: 120px;
  }

  .elv-duplicates-table-content-row__fromTo {
    width: 378px;
  }

  .elv-duplicates-table-content-row__event {
    width: 160px;
  }

  .elv-duplicates-table-content-row__operating {
    width: 60px;
    justify-content: center;

    svg {
      fill: #838d95;
      cursor: pointer;

      &:hover {
        fill: #0e0f11;
      }
    }
  }
}
</style>

<template>
  <GuideStep v-if="reportStore.entityDetail?.guideStep < 1" type="TRANSACTIONS" />
  <div v-else>
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      :updated-on="updatedOn"
      :show-regenerate="true"
      :show-regenerate-button="true"
    />

    <HeaderTabs :duplicate-total="utils.formatNumber(duplicateTotal)" :review-total="utils.formatNumber(reviewTotal)" />

    <div v-loading="loading">
      <template v-if="duplicatesData.list.length">
        <p class="elv-duplicates-groups-total">
          {{ t('report.groupsDuplicatesTotal', { total: duplicatesData.total }) }}
        </p>
        <DuplicatesTable
          :table-data="duplicatesData.list"
          :table-height="tableHeight"
          @resetDuplicateDataList="resetDuplicateDataList"
        />
      </template>

      <EmptyResult v-else :img-src="transactionsDoneImg" width="200px" height="200px">
        <template #content>
          <p class="elv-transactions-duplicates-done__title">{{ t('report.wellDone') }}!</p>
          <p class="elv-transactions-duplicates-done__desc">
            {{ t('report.duplicateTransactions') }}
          </p>
        </template>
      </EmptyResult>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { find } from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import ReportsApi from '@/api/ReportsApi'
import Header from '../components/Header.vue'
import GuideStep from '../components/GuideStep.vue'
import useInitPageIntro from '@/hooks/initPageIntro'
import HeaderTabs from './components/HeaderTabs.vue'
import { useGlobalStore } from '@/stores/modules/global'
import DuplicatesTable from './components/DuplicatesTable.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import transactionsDoneImg from '@/assets/img/reports/transactions-done.png'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const { initPageIntro } = useInitPageIntro()

const transactionsHeaderRef = ref()
const loading = ref(false)

const duplicatesData: any = ref({
  total: 0,
  fromDate: '',
  toDate: '',
  list: []
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const duplicateTotal = computed(() => {
  return find(reportStore.issueList, { type: 'DUPLICATE' })?.count ?? 0
})

const reviewTotal = computed(() => {
  return find(reportStore.issueList, { type: 'FOR_REVIEW' })?.count ?? 0
})

const updatedOn = computed(() => {
  const dateTime = duplicatesData.value?.fromDate
    ? `From ${utils.dateFormatting(duplicatesData.value?.fromDate, 'date')} to ${utils.dateFormatting(
        duplicatesData.value?.toDate,
        'date'
      )}`
    : ''
  return dateTime
})

const tableHeight = computed(() => {
  if (globalStore.showUpGradeInfo) {
    return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100vh - 313px)` : `calc(100vh - 263px)`
  }
  return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100vh - 282px)` : `calc(100vh - 232px)`
})

const resetDuplicateDataList = async () => {
  try {
    const { data } = await ReportsApi.getDuplicateList(entityId.value)
    duplicatesData.value = data
  } catch (error) {
    console.log(error)
  }
}

emitter.on('resetDuplicateDataList', resetDuplicateDataList)

watch(
  route,
  async (newValue) => {
    try {
      if (newValue.name === 'reports-transactions-duplicates') {
        loading.value = true
        reportStore.checkTransactionsAll = false
        reportStore.batchEditSelected = []
        await resetDuplicateDataList()
        if (reportStore.entityDetail.guideStep >= 2 && duplicatesData.value?.list.length) {
          nextTick(() => {
            setTimeout(() => {
              const steps = [
                {
                  element: '.elv-duplicates-table-ignore', // 这是添加引导的元素id
                  intro: t('bubbleGuide.duplicates.step1'), // 这是引导提示内容
                  position: 'right' // 这是引导位置
                },
                {
                  element: '.elv-duplicates-table-content-transaction__keepAll',
                  intro: t('bubbleGuide.duplicates.step2'),
                  position: 'bottom'
                }
              ]
              initPageIntro(steps, 'transactions-duplicates')
            }, 500)
          })
        }
        await reportStore.fetchActivityEventList(entityId.value, 'REVIEWED')
        reportStore.transactionFilter.categoryNos = reportStore.activityEventList
        reportStore.processTableListDataInit(entityId.value, 'reviewed', { limit: 10, page: 1 })
        loading.value = false
      }
    } catch (error) {
      loading.value = false
    }
  },
  { immediate: true, deep: true }
)
onBeforeUnmount(() => {
  emitter.off('resetDuplicateDataList', resetDuplicateDataList)
})
</script>

<style scoped lang="scss">
.elv-transactions-duplicates-done__title {
  font-size: 17px;
  line-height: 21px;
  color: #0e0f11;
  margin-top: 9px;
}

.elv-transactions-duplicates-done__desc {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #838d95;
  margin-top: 8px;
  word-break: break-word;
}

.elv-duplicates-groups-total {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  padding-top: 16px;
  padding-left: 21px;
  margin-bottom: 15px;
}
</style>
